/**
 * @Description: router
 * @Author: chenchen
 * @Date: 2020-04-07 21:10:07
 * @LastEditors: chenchen
 * @LastEditTime: 2023-04-08 21:45:03
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Chat',
    // lazy-loaded when the route is visited
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/chat/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
