/**
 * @Description:
 * @Author: chenchen
 * @Date: 2020-04-07 21:10:07
 * @LastEditors: chenchen
 * @LastEditTime: 2023-04-09 00:19:41
 */
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import moment from 'moment'
import hljs from 'highlight.js'
import showdown from 'showdown'
import 'babel-polyfill'
import 'event-source-polyfill'

Vue.prototype.$axios = axios
Vue.prototype.$moment = moment
hljs.configure({
  ignoreUnescapedHTML: true
})
Vue.prototype.$hljs = hljs
window.showdown = showdown
window.hljs = hljs
const converter = new showdown.Converter({
  smoothLivePreview: true,
  smartIndentationFix: true,
  simpleLineBreaks: true,
  moreStyling: true,
  ghCodeBlocks: true,
  table: true
})
converter.setFlavor('github')
Vue.prototype.$mdCovent = converter

Vue.config.productionTip = false
// vuex
import store from './store'
// vue-router
import router from './router'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
